import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import reducer from "./reducers";
import Layout from "./containers/layout/index";
import "./assets/index.css";

const store = createStore(reducer, compose(applyMiddleware(thunk)));
render(
  <Provider store={store}>
    <Layout />
  </Provider>,
  document.getElementById("root")
);
