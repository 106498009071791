import React, { Component } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";

class Leads extends Component {
  componentDidMount() {
    const { isLoading, error, fetchLeads } = this.props;
    if (!isLoading && error === null) {
      fetchLeads();
    }
  }

  render() {
    const { list, isLoading, error } = this.props;

    return (
      <div>
        {!isLoading && list !== null && error === null ? (
          <ReactTable
            data={list}
            columns={this.getTableColumns()}
            pageSizeOptions={[20, 25, 50]}
            style={{ height: "90vh" }}
            defaultSorted={[
              {
                id: "CreatedDate",
                desc: true
              }
            ]}
          />
        ) : (
          <div className="loading">
            <div className="loader"></div>
            <div className="loader-text">Loading...</div>
          </div>
        )}
      </div>
    );
  }

  getTableColumns = () => {
    const columns = [
      {
        id: "InProgress",
        Header: "In Progress",
        accessor: "Affiliate__c",
        columns: [
          {
            Header: "Affiliate",
            accessor: "Affiliate__c"
          },
          {
            Header: "Name",
            accessor: "Name",
            filterable: true
          },
          {
            id: "CreatedDate",
            Header: "Created Date",
            accessor: d => this.convertToLocaleDate(d.CreatedDate)
          },
          {
            Header: "Email",
            accessor: "Email"
          },
          {
            Header: "Phone",
            accessor: "Phone"
          },
          {
            id: "TargetkWh__c",
            Header: "Target kWh",
            accessor: d => this.getNumberWithCommas(d.TargetkWh__c)
          },
          {
            Header: "Utility",
            accessor: "Utility__c"
          },
          {
            id: "SignupUtilityEntered__c",
            Header: "Utility Entered",
            accessor: d => this.convertToLocaleDate(d.SignupUtilityEntered__c)
          },
          {
            id: "SignupBankInfoEntered__c",
            Header: "Payment Entered",
            accessor: d => this.convertToLocaleDate(d.SignupBankInfoEntered__c)
          },
          {
            Header: "Validation Issues",
            accessor: "ValidationIssues__c"
          },
          {
            Header: "Referrer Page",
            accessor: "ReferrerPageFormatted__c"
          }
        ]
      }
    ];

    return columns;
  };

  convertToLocaleDate = value => {
    if (!value) {
      return null;
    }

    return new Date(value).toLocaleDateString(
      "en-US",
      { timeZone: "UTC" },
      {
        year: "numeric",
        month: "numeric",
        day: "numeric"
      }
    );
  };

  getNumberWithCommas = value => {
    return value
      ? value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      : null;
  };
}

export default Leads;
