import React, { Component } from "react";
import { NavLink } from "react-router-dom";

class Navbar extends Component {
  render() {
    return (
      <nav className="sidebar">
        <div className="sidebar-sticky">
          <h3 className="app-name">Common Energy Affiliate Portal</h3>
          <ul className="nav flex-column">
            {/* <li className="nav-item">
              <NavLink
                className="nav-link"
                exact
                activeClassName="active"
                to="/"
              >
                <span>&#10148;</span>Dashboard
              </NavLink>
            </li> */}
            <li className="nav-item">
              <NavLink
                className="nav-link"
                exact
                activeClassName="active"
                to="/leads"
              >
                <span>&#10148;</span>Leads in Progress
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink
                className="nav-link"
                exact
                activeClassName="active"
                to="/meters"
              >
                <span>&#10148;</span>Enrolled Customers
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default Navbar;
