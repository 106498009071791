import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'
import routes from '../../routes'
import { auth } from '../../firebase/firebase.util'
import TopBar from '../../containers/layout/top-bar'
import Navbar from './navbar'

class Layout extends Component {
  componentDidMount() {
    const { setAuthorizedUser } = this.props

    auth.onAuthStateChanged(authUser => {
      setAuthorizedUser(authUser)
    })
  }

  render() {
    const { user } = this.props

    if (!user.isAuthSet) {
      return <div className='loading'>
          <div className="loader"></div>
          <div className="loader-text">Loading...</div>
      </div>
    }

    return <Router>
      <Switch>
        {
          routes.map((route, index) => <Route
            key={index}
            path={route.path}
            exact={route.exact}
            render={props => this.getRouteRenderedComponent(props, route)}
          />)
        }
      </Switch>
    </Router>
  }

  getRouteRenderedComponent(props, route) {
    const { user } = this.props

    if (route.protected) {
      if (!user.authorizedUser) {
        return <Redirect to='/sign-in' />
      }

      return <div>
        <TopBar {...props}/>
        <Navbar />
        <div className="main">
          <route.Component {...props} />  
        </div>
      </div >
    } else {
      return <route.Component  {...props} />
    }
  }
}

export default Layout
