import TopBar from '../../components/layout/top-bar'
import { signOut } from '../../actions/user'
import { connect } from 'react-redux'

const mapStateToProps = (state, ownProps) => {
  return {
    authorizedUser: state.user.authorizedUser
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    signOut: () => dispatch(signOut(ownProps.history.push)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TopBar)