import Dashboard from './components/dashboard'
import Leads from './containers/leads'
import Meters from './containers/meters'
import SignIn from './containers/sign-in'
import ResetPassword from './containers/reset-password'

const routes = [
  {
    exact: true,
    path: '/',
    Component: Dashboard,
    protected: true
  },
  {
    exact: true,
    path: '/leads',
    Component: Leads,
    protected: true
  },
  {
    exact: true,
    path: '/meters',
    Component: Meters,
    protected: true
  },
  {
    exact: true,
    path: '/sign-in',
    Component: SignIn,
    protected: false
  },
  {
    exact: true,
    path: '/reset-password',
    Component: ResetPassword,
    protected: false
  },
]

export default routes
