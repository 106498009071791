import SignIn from '../../components/sign-in'
import { changeSigningInUserProperty, signIn, clearError, clearResetPassword } from '../../actions/user'
import { connect } from 'react-redux'

const mapStateToProps = (state, ownProps) => {
  return {
    email: state.user.signingInUser.email,
    password: state.user.signingInUser.password,
    isSigningIn: state.user.isSigningIn,
    error: state.user.error
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    changeSigningInUserProperty: (name, value) => dispatch(changeSigningInUserProperty(name, value)),
    signIn: () => dispatch(signIn(ownProps.history.push)),
    clearError: () => dispatch(clearError()),
    clearResetPassword: () => dispatch(clearResetPassword()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn)