import Layout from '../../components/layout'
import { setAuthorizedUser } from '../../actions/user'
import { connect } from 'react-redux'

const mapStateToProps = (state, ownProps) => {
  return {
    user: state.user
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    setAuthorizedUser: authUser => dispatch(setAuthorizedUser(authUser)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)