import { ACTION_TYPES } from '../actions/user'
import { merge } from 'lodash'

const defaultState = {
  signingInUser: {
    email: '',
    password: '',
  },
  isSigningIn: false,
  isSigningOut: false,

  authorizedUser: null,
  isAuthSet: false,

  isFetchingIdToken: false,
  idToken: null,

  email: '',
  hasResetPasswordEmailSent: false,
  isSendingResetPasswordEmail: false,

  error: null,
}

const user = (state = defaultState, action) => {
  switch (action.type) {
    case ACTION_TYPES.SIGNING_IN_USER_PROPERTY_CHANGE: {
      let nextState = merge({}, state)
      nextState.signingInUser[action.name] = action.value
      return nextState
    }

    case ACTION_TYPES.SIGN_IN_REQUEST_INIT:
      return {
        ...state,
        isSigningIn: true,
        error: null
      }

    case ACTION_TYPES.SIGN_IN_REQUEST_SUCCESS:
      return {
        ...state,
        signingInUser: {
          email: '',
          password: '',
        },
        isSigningIn: false,
        isAuthSet: false,
        error: null
      }

    case ACTION_TYPES.SIGN_IN_REQUEST_ERROR:
      return {
        ...state,
        signingInUser: {
          email: '',
          password: '',
        },
        isSigningIn: false,
        error: action.error
      }

    case ACTION_TYPES.SET_AUTHORIZED_USER:
      return {
        ...state,
        isAuthSet: true,
        authorizedUser: action.authUser ? action.authUser : null,
      }

    case ACTION_TYPES.SIGN_OUT_REQUEST_INIT:
      return {
        ...state,
        isSigningOut: true,
        error: null
      }

    case ACTION_TYPES.SIGN_OUT_REQUEST_SUCCESS:
      return { ...defaultState }

    case ACTION_TYPES.SIGN_OUT_REQUEST_ERROR:
      return {
        ...state,
        isSigningOut: false,
        error: action.error
      }

    case ACTION_TYPES.ID_TOKEN_REQUEST_INIT:
      return {
        ...state,
        isFetchingIdToken: true,
        error: null
      }

    case ACTION_TYPES.ID_TOKEN_REQUEST_SUCCESS:
      return {
        ...state,
        isFetchingIdToken: false,
        idToken: action.idToken,
        error: null
      }

    case ACTION_TYPES.ID_TOKEN_REQUEST_ERROR:
      return {
        ...state,
        isFetchingIdToken: false,
        error: action.error
      }

    case ACTION_TYPES.RESETTING_PASSWORD_PROPERTY_CHANGE: {
      let nextState = merge({}, state)
      nextState.email = action.value
      return nextState
    }

    case ACTION_TYPES.SEND_PASSWORD_RESET_EMAIL_REQUEST_INIT:
      return {
        ...state,
        isSendingResetPasswordEmail: true,
        error: null
      }

    case ACTION_TYPES.SEND_PASSWORD_RESET_EMAIL_REQUEST_SUCCESS:
      return {
        ...state,
        isSendingResetPasswordEmail: false,
        hasResetPasswordEmailSent: true,
        error: null
      }

    case ACTION_TYPES.SEND_PASSWORD_RESET_EMAIL_REQUEST_ERROR:
      return {
        ...state,
        isSendingResetPasswordEmail: false,
        error: action.error
      }

    case ACTION_TYPES.CLEAR_ERROR:
      return {
        ...state,
        error: null
      }

    case ACTION_TYPES.CLEAR_RESET_PASSWORD:
      return {
        ...state,
        hasResetPasswordEmailSent: false,
        email: ''
      }

    default:
      return state
  }
}

export default user