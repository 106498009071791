import React, { Component } from 'react'

class TopBar extends Component {
  render() {
    const { authorizedUser, signOut } = this.props

    return <div className="top-bar">
      <div className='user-name'>{authorizedUser.displayName ? authorizedUser.displayName : ''}</div>
      {
        authorizedUser ?
          <button className='sign-out-button' type="button" onClick={() => signOut()}>
            Sign Out
          </button> : null
      }
    </div>
  }
}

export default TopBar
