import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";

class SignIn extends Component {
  componentDidMount() {
    const { clearError, clearResetPassword } = this.props;
    clearError();
    clearResetPassword();
  }

  render() {
    const { email, password, isSigningIn, error } = this.props;
    const isInvalid = password === "" || email === "";

    return (
      <div className="sign-in-page">
        <div className="sign-in-logo">
          <img src="logo.png" alt="logo" />
        </div>
        <div className="sign-in-form">
          <h3 className="title">Sign In</h3>
          <form onSubmit={this.signInHandler}>
            <input
              name="email"
              value={email}
              onChange={this.changeHandler}
              type="text"
              placeholder="Email Address"
            />
            <input
              name="password"
              value={password}
              onChange={this.changeHandler}
              type="password"
              placeholder="Password"
            />
            <button disabled={isInvalid} type="submit">
              {isSigningIn && (
                <span className="mini-loading">
                  <span className="mini-loader"></span>
                </span>
              )}
              <span>Next</span>
            </button>
            <p className="forget-password-link">
              Forget password? It's ok.{" "}
              <Link to="/reset-password">Recover here</Link>
            </p>
            {error && <p className="error-message">{error.message}</p>}
          </form>
        </div>
      </div>
    );
  }

  changeHandler = event => {
    const { changeSigningInUserProperty } = this.props;
    changeSigningInUserProperty(event.target.name, event.target.value);
  };

  signInHandler = event => {
    event.preventDefault();
    const { signIn } = this.props;
    signIn();
  };
}

export default withRouter(SignIn);
