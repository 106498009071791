import { auth } from "../firebase/firebase.util";

export const ACTION_TYPES = {
  SIGNING_IN_USER_PROPERTY_CHANGE: "SIGNING_IN_USER_PROPERTY_CHANGE",

  SIGN_IN_REQUEST_INIT: "SIGN_IN_REQUEST_INIT",
  SIGN_IN_REQUEST_SUCCESS: "SIGN_IN_REQUEST_SUCCESS",
  SIGN_IN_REQUEST_ERROR: "SIGN_IN_REQUEST_ERROR",

  SET_AUTHORIZED_USER: "SET_AUTHORIZED_USER",

  SIGN_OUT_REQUEST_INIT: "SIGN_OUT_REQUEST_INIT",
  SIGN_OUT_REQUEST_SUCCESS: "SIGN_OUT_REQUEST_SUCCESS",
  SIGN_OUT_REQUEST_ERROR: "SIGN_OUT_REQUEST_ERROR",

  ID_TOKEN_REQUEST_INIT: "ID_TOKEN_REQUEST_INIT",
  ID_TOKEN_REQUEST_SUCCESS: "ID_TOKEN_REQUEST_SUCCESS",
  ID_TOKEN_REQUEST_ERROR: "ID_TOKEN_REQUEST_ERROR",

  RESETTING_PASSWORD_PROPERTY_CHANGE: "RESETTING_PASSWORD_PROPERTY_CHANGE",
  SEND_PASSWORD_RESET_EMAIL_REQUEST_INIT:
    "SEND_PASSWORD_RESET_EMAIL_REQUEST_INIT",
  SEND_PASSWORD_RESET_EMAIL_REQUEST_SUCCESS:
    "SEND_PASSWORD_RESET_EMAIL_REQUEST_SUCCESS",
  SEND_PASSWORD_RESET_EMAIL_REQUEST_ERROR:
    "SEND_PASSWORD_RESET_EMAIL_REQUEST_ERROR",

  CLEAR_ERROR: "CLEAR_ERROR",
  CLEAR_RESET_PASSWORD: "CLEAR_RESET_PASSWORD"
};

export const changeSigningInUserProperty = (name, value) => {
  return dispatch => {
    dispatch({
      type: ACTION_TYPES.SIGNING_IN_USER_PROPERTY_CHANGE,
      name,
      value
    });
  };
};

export const signIn = historyPusher => {
  return (dispatch, getState) => {
    const signingInUser = getState().user.signingInUser;

    dispatch({ type: ACTION_TYPES.SIGN_IN_REQUEST_INIT });
    return auth
      .signInWithEmailAndPassword(signingInUser.email, signingInUser.password)
      .then(() => {
        dispatch({ type: ACTION_TYPES.SIGN_IN_REQUEST_SUCCESS });
        historyPusher("/leads");
      })
      .catch(error => {
        dispatch({ type: ACTION_TYPES.SIGN_IN_REQUEST_ERROR, error });
      });
  };
};

export const setAuthorizedUser = authUser => {
  return dispatch => {
    dispatch({ type: ACTION_TYPES.SET_AUTHORIZED_USER, authUser });
    if (authUser) {
      dispatch(getUserIdToken(authUser));
    }
  };
};

export const signOut = historyPusher => {
  return dispatch => {
    dispatch({ type: ACTION_TYPES.SIGN_OUT_REQUEST_INIT });
    return auth
      .signOut()
      .then(() => {
        dispatch({ type: ACTION_TYPES.SIGN_OUT_REQUEST_SUCCESS });
        historyPusher("/sign-in");
      })
      .catch(error => {
        dispatch({ type: ACTION_TYPES.SIGN_OUT_REQUEST_ERROR, error });
      });
  };
};

export const getUserIdToken = user => {
  return (dispatch, getState) => {
    dispatch({ type: ACTION_TYPES.ID_TOKEN_REQUEST_INIT });
    return user
      .getIdToken()
      .then(idToken => {
        dispatch({ type: ACTION_TYPES.ID_TOKEN_REQUEST_SUCCESS, idToken });
      })
      .catch(error => {
        dispatch({ type: ACTION_TYPES.ID_TOKEN_REQUEST_ERROR, error });
      });
  };
};

export const changeResetPasswordProperty = value => {
  return dispatch => {
    dispatch({ type: ACTION_TYPES.RESETTING_PASSWORD_PROPERTY_CHANGE, value });
  };
};

export const resetPassword = () => {
  return (dispatch, getState) => {
    const emailAddress = getState().user.email;
    dispatch({ type: ACTION_TYPES.SEND_PASSWORD_RESET_EMAIL_REQUEST_INIT });

    const actionCodeSettings = {
      url: "https://team.commonenergy.us/sign-in",
      handleCodeInApp: false
    };

    return auth
      .sendPasswordResetEmail(emailAddress, actionCodeSettings)
      .then(() => {
        dispatch({
          type: ACTION_TYPES.SEND_PASSWORD_RESET_EMAIL_REQUEST_SUCCESS
        });
      })
      .catch(error => {
        dispatch({
          type: ACTION_TYPES.SEND_PASSWORD_RESET_EMAIL_REQUEST_ERROR,
          error
        });
      });
  };
};

export const clearError = () => {
  return dispatch => {
    dispatch({ type: ACTION_TYPES.CLEAR_ERROR });
  };
};

export const clearResetPassword = () => {
  return dispatch => {
    dispatch({ type: ACTION_TYPES.CLEAR_RESET_PASSWORD });
  };
};
