import React, { Component } from 'react'

class Dashboard extends Component {
  render() {
    return <div className='dashboard'>
    </div>
  }
}

export default Dashboard
