import ResetPassword from '../../components/reset-password'
import { changeResetPasswordProperty, resetPassword, clearError } from '../../actions/user'
import { connect } from 'react-redux'

const mapStateToProps = (state, ownProps) => {
  return {
    email: state.user.email,
    hasResetPasswordEmailSent: state.user.hasResetPasswordEmailSent,
    isSendingResetPasswordEmail: state.user.isSendingResetPasswordEmail,
    error: state.user.error
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    changeResetPasswordProperty: (value) => dispatch(changeResetPasswordProperty(value)),
    resetPassword: () => dispatch(resetPassword()),
    clearError: () => dispatch(clearError()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword)