import React, { Component } from "react";
import { Link } from "react-router-dom";

class ResetPassword extends Component {
  componentDidMount() {
    const { clearError } = this.props;
    clearError();
  }

  render() {
    const {
      email,
      hasResetPasswordEmailSent,
      isSendingResetPasswordEmail,
      error
    } = this.props;
    const isInvalid = email === "";

    return (
      <div className="reset-password-page">
        <div className="reset-password-logo">
          <img src="logo.png" alt="logo" />
        </div>
        <div className="reset-password-form">
          {
            <form onSubmit={this.resetPasswordHandler}>
              {hasResetPasswordEmailSent === false ? (
                <div>
                  <h3>Forgot password?</h3>
                  <p>
                    Please enter your email address below and we will send you
                    information to recover your password.
                  </p>
                  <input
                    name="email"
                    value={email}
                    onChange={this.changeHandler}
                    type="text"
                    placeholder="Email Address"
                  />
                  <button
                    disabled={isInvalid || isSendingResetPasswordEmail}
                    type="submit"
                  >
                    {isSendingResetPasswordEmail && (
                      <span className="mini-loading">
                        <span className="mini-loader"></span>
                      </span>
                    )}
                    <span>Reset Password</span>
                  </button>
                </div>
              ) : (
                <div>
                  <div className="reset-email-sent-message">
                    A link has been sent to your email, please check your email
                  </div>
                  <button disabled={isSendingResetPasswordEmail} type="submit">
                    {isSendingResetPasswordEmail && (
                      <span className="mini-loading">
                        <span className="mini-loader"></span>
                      </span>
                    )}
                    <span>Resend Password Email</span>
                  </button>
                </div>
              )}
              <Link to="/sign-in">Sign In</Link>
              {error && <p className="error-message">{error.message}</p>}
            </form>
          }
        </div>
      </div>
    );
  }

  changeHandler = event => {
    const { changeResetPasswordProperty } = this.props;
    changeResetPasswordProperty(event.target.value);
  };

  resetPasswordHandler = event => {
    event.preventDefault();
    const { resetPassword } = this.props;
    resetPassword();
  };
}

export default ResetPassword;
