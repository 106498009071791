import Leads from '../../components/leads'
import { fetchLeads } from '../../actions/leads'
import { connect } from 'react-redux'

const mapStateToProps = (state, ownProps) => {
  return {
    list: state.leads.list,
    isLoading: state.leads.isLoading,
    error: state.leads.error,
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    fetchLeads: () => dispatch(fetchLeads()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Leads)